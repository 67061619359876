import React from 'react';
import 'moment/locale/ru';
import {Checkbox, Form, Input, Select} from 'antd';
import { enumFieldName, t } from '../../../utils/helpers/formatFieldsLabels';
import { formItemLayout } from '../../../utils/constants';

interface IIntegrationFieldsProps {
    fieldName: string;
    infoFields: { properties?: Record<string, any>; required?: string[] };
}
const IntegrationFields: React.FC<IIntegrationFieldsProps> = ({ fieldName, infoFields }) => {
    const renderInput = (itemName: string) => {
        const type = infoFields?.properties?.[itemName]?.type || null;
        switch (type) {
            case 'boolean':
                return <Checkbox />;
            case 'array':
                return <Select mode="tags" />;
            default:
                return <Input />;
        }
    };
    const normalizeValue = (value: string, type: string) => {
        if (value === '') {
            return ;
        }

        switch (type) {
            case 'boolean':
                return value === 'true';
            case 'number':
            case 'integer':
                return !value ? null : Number(value);
            case 'string':
            default:
                return value;
        }
    };

    const renderItem = (itemName: string) => {
        const rules = [];

        if (infoFields?.required?.includes(itemName)) {
            rules.push({ required: true });
        }
        const type = infoFields?.properties?.[itemName]?.type || null;
        const additionalProperties = type === 'boolean' ? { valuePropName: 'checked' } : {};

        const formItemProps = {
            ...formItemLayout,
            ...additionalProperties,
            key: itemName,
            name: [fieldName, itemName],
            label: t(itemName as enumFieldName),
            rules,
            normalize: (value: string) => normalizeValue(value, type),
        };

        return <Form.Item {...formItemProps}>{renderInput(itemName)}</Form.Item>;
    };

    if (!infoFields) {
        return null;
    }

    return (
        <>
            {Object.keys(infoFields.properties || {}).map((itemName) => renderItem(itemName))}
        </>
    );
};

export default IntegrationFields;
