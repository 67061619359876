import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Row } from 'antd';
import { CloseSquareTwoTone } from '@ant-design/icons';
import { useAppDispatch } from '../../store';
import { IFile } from '../../store/Crud/types/file';
import { getFile, uploadGoodImage } from '../../store/File';
import { resource } from '../../utils/constants';
import { baseStaticUrl } from '../../utils/http';
import { IGood } from '../../store/Crud/types/good';

type TProps = {
    good: IGood;
    update: (resource: string, id: string, payload: IGood) => IGood;
};

const GoodImages: FC<TProps> = ({ good, update }) => {
    const dispatch = useAppDispatch();
    const inputOpenFileRef = useRef<any>(null);
    const { id: goodId } = good;

    const [goodImages, setGoodImages] = useState<null | Array<IFile>>(null);

    const onFileUpload = async (event: React.FormEvent<HTMLInputElement>) => {
        const { files } = event.target as HTMLInputElement;
        if (files && files[0] !== undefined) {
            const { payload }: any = await dispatch(uploadGoodImage({ uploadFile: files[0], goodId }));
            const newGoodImageId = payload?.data?.data?.id;

            if (newGoodImageId) {
                await // @ts-ignore
                update(resource.GOOD, goodId.toString(), {
                    ...good,
                    imageIds: good.imageIds ? [...good.imageIds, newGoodImageId] : [newGoodImageId],
                })();
            }
        }
    };

    const handleFileImageDownload = useCallback(async () => {
        const result =
            good.imageIds && good.imageIds.length > 0
                ? await dispatch(getFile(good.imageIds))
                      .unwrap()
                      .then(({ data }) => data)
                : null;

        const filteredResult = result ? result.filter((i) => i) : null;
        setGoodImages(filteredResult);
    }, [dispatch, good.imageIds]);

    useEffect(() => {
        handleFileImageDownload();
    }, [handleFileImageDownload]);

    const showOpenFileDlg = () => {
        // @ts-ignore
        inputOpenFileRef?.current?.click();
    };

    const handleImageRemove = (id: number) => {
        // @ts-ignore
        update(resource.GOOD, goodId.toString(), {
            ...good,
            imageIds: good?.imageIds?.filter((imageId) => imageId !== id),
        })();
    };

    return (
        <Card
            title="Изображения"
            style={{ minWidth: 380 }}
            extra={
                <>
                    <input
                        ref={inputOpenFileRef}
                        onClick={(e) => e.stopPropagation()}
                        type="file"
                        onChange={onFileUpload}
                        style={{ display: 'none' }}
                    />
                    <Button onClick={showOpenFileDlg} style={{ display: 'inline-block' }}>
                        Загрузить изображение
                    </Button>
                </>
            }
        >
            <Row align="middle" gutter={4}>
                {goodImages &&
                    goodImages?.length > 0 &&
                    goodImages.map((image) => (
                        <Col style={{ position: 'relative' }} key={image.id}>
                            <CloseSquareTwoTone
                                style={{ position: 'absolute', top: 0, right: 0 }}
                                onClick={() => handleImageRemove(image.id)}
                            />
                            <img alt={image.path} src={`${baseStaticUrl}100x66/${image.path}`} />
                        </Col>
                    ))}
            </Row>
        </Card>
    );
};

export default GoodImages;
