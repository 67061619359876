import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Button, Row, Col, Card } from 'antd';
import { CloseSquareTwoTone } from '@ant-design/icons';
import { getFile, uploadShopFile } from '../../../store/File';
import { useAppDispatch } from '../../../store';
import { enumTypeImage } from '../../../store/File/types';
import { update } from '../../../store/Crud';
import { resource } from '../../../utils/constants';
import { IShop } from '../../../store/Crud/types';
import { IFile } from '../../../store/Crud/types/file';
import { baseStaticUrl } from '../../../utils/http';

type TProps = {
    shop: IShop;
};

const ShopImages: FC<TProps> = ({ shop }) => {
    const dispatch = useAppDispatch();
    const inputOpenFileRef = useRef<any>(null);
    const { id: shopId } = shop;

    const [shopImages, setShopImages] = useState<null | Array<IFile>>(null);

    const onFileUpload = async (event: React.FormEvent<HTMLInputElement>) => {
        const { files } = event.target as HTMLInputElement;
        if (files && files[0] !== undefined) {
            const { payload }: any = await dispatch(
                uploadShopFile({ uploadFile: files[0], shopId, type: enumTypeImage.IMAGE }),
            );
            const newShopImageId = payload?.data?.data?.id;
            if (newShopImageId) {
                await dispatch(
                    update(resource.SHOP, shopId.toString(), {
                        ...shop,
                        imageIds: shop.imageIds ? [...shop.imageIds, newShopImageId] : [newShopImageId],
                    })(),
                );
            }
        }
    };

    const handleFileImageDownload = useCallback(async () => {
        const result =
            shop.imageIds && shop.imageIds.length > 0
                ? await dispatch(getFile(shop.imageIds))
                      .unwrap()
                      .then(({ data }) => data)
                : null;
        const filteredResult = result ? result.filter((i) => i) : null;

        setShopImages(filteredResult);
    }, [dispatch, shop.imageIds]);

    useEffect(() => {
        handleFileImageDownload();
    }, [handleFileImageDownload]);

    const showOpenFileDlg = () => {
        // @ts-ignore
        inputOpenFileRef?.current?.click();
    };

    const handleImageRemove = (id: number) => {
        dispatch(
            update(resource.SHOP, shopId.toString(), {
                ...shop,
                imageIds: shop?.imageIds?.filter((imageId) => imageId !== id),
            })(),
        );
    };

    return (
        <Card
            title="Изображения"
            style={{ minWidth: 380 }}
            extra={
                <>
                    <input
                        ref={inputOpenFileRef}
                        onClick={(e) => e.stopPropagation()}
                        type="file"
                        onChange={onFileUpload}
                        style={{ display: 'none' }}
                    />
                    <Button onClick={showOpenFileDlg} style={{ display: 'inline-block' }}>
                        Загрузить изображение
                    </Button>
                </>
            }
        >
            <Row align="middle" gutter={4}>
                {shopImages &&
                    shopImages?.length > 0 &&
                    shopImages.map((image) => (
                        <Col style={{ position: 'relative' }} key={image.id}>
                            <CloseSquareTwoTone
                                style={{ position: 'absolute', top: 0, right: 0 }}
                                onClick={() => handleImageRemove(image.id)}
                            />
                            <img alt={image.path} src={`${baseStaticUrl}100x66/${image.path}`} />
                        </Col>
                    ))}
            </Row>
        </Card>
    );
};

export default ShopImages;
