import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useAppDispatch } from '../../store';
import {checkSession, logout} from '../../store/Auth';
import { getCurrentUserData } from '../../store/Auth/selectors';
import {useHistory} from "react-router-dom";
import {createLoginLink} from "../../components/Linker/PathCreators";

const Logout = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const waitResponse = useSelector(getCurrentUserData)?.waitResponse;

    const onClickLogoutButton = async () => {
        await dispatch(logout());
        history.push(createLoginLink());
        dispatch(checkSession());
    };

    return (
        <div className="auth-form">
            <Button
                icon={waitResponse ? <LoadingOutlined /> : null}
                disabled={waitResponse}
                className="login-btn"
                htmlType="submit"
                onClick={onClickLogoutButton}
            >
                Выйти из системы
            </Button>
        </div>
    );
};

export default Logout;
