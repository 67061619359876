import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import RestorePasswordCard from './ResetPassword';
import LoginCard from './Auth';
import { createLoginLink, createResetPasswordLink } from '../components/Linker/PathCreators';

export const NotAuthRoutes = () => (
    <div className="content">
        <Router>
            <Switch>
                <Route path={createLoginLink()}>
                    <LoginCard />
                </Route>
                <Route path={createResetPasswordLink()}>
                    <RestorePasswordCard />
                </Route>
                {/*<Redirect to={createLoginLink()} />*/}
            </Switch>
        </Router>
    </div>
);
