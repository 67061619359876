import React, { ChangeEvent, FC, useRef } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useSelector } from 'react-redux';
import { isCrudWait } from '../../../store/Crud/selectors';
import { resource } from '../../../utils/constants';
import Loading from '../../../components/Loading';

interface IFileUpload {
    onFileUploaded: (file: any) => void;
}

const FileUpload: FC<IFileUpload> = ({ onFileUploaded }) => {
    const inputOpenFileRef = useRef<any>(null);

    const isWaiting = useSelector(isCrudWait(resource.FILE));

    const fileUploaded = (e: ChangeEvent) => {
        const event = e.target as HTMLInputElement;
        const files = event?.files as FileList;
        if (files?.[0] !== undefined) {
            onFileUploaded(files?.[0]);
        }
    };

    const showOpenFileDlg = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        inputOpenFileRef?.current?.click();
        e.stopPropagation();
    };

    if (isWaiting) {
        return <Loading />;
    }

    return (
        <div style={{ display: 'inline-block' }}>
            <input
                ref={inputOpenFileRef}
                onClick={(e) => e.stopPropagation()}
                type="file"
                onChange={fileUploaded}
                style={{ display: 'none' }}
            />
            <Button onClick={(e) => showOpenFileDlg(e)} icon={<UploadOutlined />} />
        </div>
    );
};

export default FileUpload;
